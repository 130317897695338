import React from 'react'
import styled from 'styled-components'

const StyledCaseData = styled.div`
  color: #4f4f4f;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  width: 60%;
  line-height: 1.45;
  margin: 4rem 0 4rem;
  z-index: 2;
  position: relative;

  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin: 2rem 0 2rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.7rem 0;
`

const Label = styled.div`
  width: 100px;
  min-width: 100px;
  margin-right: 60px;
  opacity: 0.6;
  @media (max-width: 1250px) {
    margin-right: 30px;
  }
`

const Text = styled.div`
  color: #333333;
`

const CaseData = ({ description, descriptionTwo, year, roles }) => (
  <StyledCaseData>
    <Row>
      <Label>Description</Label>
      <div>
        <Text style={{ marginBottom: '1rem' }}>{description}</Text>
        <Text style={{ marginBottom: '1rem' }}>{descriptionTwo}</Text>
      </div>
    </Row>
    <Row>
      <Label>Year</Label>
      <Text>{year}</Text>
    </Row>
    <Row>
      <Label>Role</Label>
      <Text>{roles}</Text>
    </Row>
  </StyledCaseData>
)

export default CaseData
