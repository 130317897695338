import React from 'react'
import styled, { keyframes } from 'styled-components'
import posed from 'react-pose'
import Logo from '../components/logo'
import TwoButtons from '../components/twoButtons'
import Img from 'gatsby-image'

const IntroTransitionStates = {
  startState: {
    opacity: 0,
    y: 25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 600,
      delay: 300,
    },
  },
  exitedState: {
    opacity: 1,
    y: 0,
  },
}

const BelowLogoTransitionStates = {
  // startState: {
  //   opacity: 0,
  //   y: 25,
  // },
  // visible: {
  //   opacity: 1,
  //   y: 0,
  //   transition: {
  //     duration: 600,
  //     delay: 1500,
  //   },
  // },
  // exitedState: {
  //   opacity: 0,
  //   y: 0,
  // },
}

const DescriptionTransitionStates = {}

const IndexTransitionStates = {}

const ItemTransitionStates = {
  startState: {},
  visible: {},
  exitedState: {},
  logoChain: {},
  focused: {
    color: '#4f4f4f',
  },
}

const ItemsTransitionStates = {
  startState: {},
  visible: {},
  exitedState: {},
  logoChain: {},
}

const MarkerTransitionStates = {
  startState: {},
  visible: {},
  exitedState: {},
  logoChain: {},
}

const MarkingTransitionStates = {
  startState: {
    opacity: 0.5,
    width: '0%',
  },
  visible: {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 600,
      delay: 600,
      ease: [0.89, 0.01, 0.83, 0.67],
    },
  },
}

const MarkingTwoTransitionStates = {
  startState: {
    opacity: 0.5,
    width: '0%',
  },
  visible: {
    opacity: 1,
    width: '105%',
    transition: {
      duration: 800,
      delay: 1100,
      ease: [0.89, 0.01, 0.83, 0.67],
    },
  },
}

const IntroContainer = styled.div``

const StyledIntro = styled(posed.div(IntroTransitionStates))`
  display: flex;
  flex-direction: row;
  max-width: 1250px;
  margin: 10rem auto 0;
  z-index: 2;
  opacity: 0;
  @media (max-width: 1250px) {
    flex-direction: column;
    margin: 6rem auto 0;
  }
`

const BelowLogo = styled(posed.div(BelowLogoTransitionStates))`
  opacity: 1;
`

const Description = styled(posed.div(DescriptionTransitionStates))`
  font-family: -apple-system-headline, -apple-system, BlinkMacSystemFont,
    'Inter', Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: normal;
  font-size: 45px;
  color: #333;
  width: 90%;
  letter-spacing: -1px;
  margin: 0 0 3rem;
  @media (max-width: 1250px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    margin: 0 1rem 3rem;
  }
`

const ProfilePhoto = styled(Img)`
  width: 35%;
  height: 35%;
  border-radius: 50%;
  margin-right: 3rem;
  box-shadow: 0 27px 15px -20px rgba(0, 0, 0, 0.07);
  img,
  div,
  picture,
  picture > img {
    border-radius: 50%;
  }

  @media (max-width: 1250px) {
    flex-direction: column;
    margin-right: 0rem;
    margin-bottom: 3rem;
    width: 20%;
    height: 20%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 1rem 1rem 2rem;
    width: 40%;
  }
`

const Emphasis = styled.span`
  color: #333;
  position: relative;
  display: inline-block;
  white-space: nowrap;
`

const MarkingOne = styled(posed.div(MarkingTransitionStates))`
  position: absolute;
  height: 65px;
  top: -3px;
  left: -3px;
  right: 3px;
  bottom: 0px;
  z-index: -1;
  @media (max-width: 768px) {
    height: 45px;
  }
`

const MarkingTwo = styled(posed.div(MarkingTwoTransitionStates))`
  position: absolute;
  height: 65px;
  top: 3px;
  left: -3px;
  right: 3px;
  bottom: 0px;
  z-index: -1;
  @media (max-width: 768px) {
    height: 45px;
  }
`

const Index = styled(posed.div(IndexTransitionStates))`
  margin: 3.5rem 0 0 0rem;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    margin-left: 1rem;
  }
`

const Item = styled(posed.li(ItemTransitionStates))`
  color: #bdbdbd;
  display: block;
  list-style-type: none;
  padding: 0.5rem 0 0.5rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  &:first-child {
    padding-top: 0.25rem;
  }
  &:last-child {
    padding-bottom: 0.25rem;
  }
`

const Items = styled(posed.ul(ItemsTransitionStates))`
  margin: 0 0 0 1rem;
`

const Indicator = styled.div`
  width: 3px;
  border-radius: 5px;
  background-color: #e0e0e0;
  overflow: hidden;
`

const Marker = styled(posed.div(MarkerTransitionStates))`
  display: flex;
  width: 3px;
  border-radius: 5px;
  height: 32px;
  background-color: #4f4f4f;
  position: relative;
  top: 0;
  opacity: 0;
`

const rotate = keyframes`
  0% {
    transform: translateY(-32px);
    opacity: 0;
  }

  10% {
    transform: translateY(0px);
    
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(180px);
    opacity: 0;
  }
`

const MarkerPulse = styled.div`
  display: relative;
  width: 3px;
  border-radius: 5px;
  height: 32px;
  background-color: #333;
  opacity: 1;
  position: relative;
  top: -32px;
  transform-origin: top center;
  animation: ${rotate} 3s ease-out infinite;
`

const SubDescription = styled.h5`
  color: #4f4f4f;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  line-height: normal;
  font-size: 16px;
  width: 35%;
  margin: 0 0 1.5rem;
  @media (max-width: 1250px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 1.1rem;
    margin-bottom: 1.5rem;
  }
`

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoStatus: 'startState',
      indexPos: 'fluid',
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    this.setState({
      indexPos: 'fixed',
    })
  }

  render() {
    return (
      <IntroContainer>
        <Logo />
        <StyledIntro ref={this.props.innerRef}>
          <ProfilePhoto
            loading="eager"
            fluid={this.props.profilePhoto.childImageSharp.fluid}
          />
          <BelowLogo>
            <Description>
              Hi! I’m Oscar — a{' '}
              <Emphasis>
                {' '}
                product designer
                <MarkingOne>
                  <svg
                    viewBox="0 0 389 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                  >
                    <path
                      d="M0.52783 12.8572C0.469184 9.44165 3.26986 6.66643 6.68469 6.75623L156.397 10.6935L338.897 8.42042L381.479 2.82968C383.164 2.6084 384.853 3.21427 386.013 4.45659V4.45659C387.222 5.75144 387.859 7.47912 387.778 9.24905L386.574 35.6832L387.848 58.8273C387.925 60.2378 387.633 61.6442 386.999 62.9068V62.9068C385.598 65.6983 382.718 67.4362 379.596 67.3741L197.066 63.7429L8.58366 69.2908C4.6858 69.4055 1.44569 66.313 1.37874 62.414L0.52783 12.8572Z"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="389"
                        y1="37"
                        x2="-6e-06"
                        y2="37"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F2D64C" stopOpacity="0.32" />
                        <stop
                          offset="1"
                          stopColor="#F2D64C"
                          stopOpacity="0.5"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </MarkingOne>
              </Emphasis>{' '}
              working on systems and interaction between{' '}
              <Emphasis>
                people and software.
                <MarkingTwo>
                  <svg
                    viewBox="0 0 473 73"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                  >
                    <path
                      d="M0.500161 13.001C0.500821 9.79018 3.63194 7.51284 6.68687 8.5013L14.2563 10.9505C16.407 11.6463 18.6486 12.0214 20.9087 12.0634L190.622 15.2224L412.355 12.1674L465.213 6.82146C466.606 6.68055 468 7.08574 469.1 7.95171V7.95171C470.856 9.33316 471.815 11.4929 471.663 13.7216L470.127 36.2656L471.485 56.0824C471.609 57.8853 471.119 59.677 470.097 61.167V61.167C468.586 63.3691 466.077 64.674 463.406 64.6472L239.734 62.4062L12.0772 68.3857C6.56643 68.5304 1.9779 64.1881 1.81882 58.6777L0.500161 13.001V13.001Z"
                      fill="url(#paint1_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint1_linear"
                        x1="1"
                        y1="39"
                        x2="473"
                        y2="39.0001"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#42EA88" stopOpacity="0.45" />
                        <stop
                          offset="1"
                          stopColor="#42EA88"
                          stopOpacity="0.35"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </MarkingTwo>
              </Emphasis>
            </Description>
            <SubDescription>Sounds like fun? Let’s chat —</SubDescription>
            <TwoButtons />
            <Index pose={this.state.indexPos}>
              <Indicator>
                <Marker />
                <MarkerPulse />
              </Indicator>
              <Items>
                <Item pose={'focused'}>Start</Item>
                <Item
                  onClick={() => {
                    this.props.minecraftRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    })
                  }}
                >
                  Minecraft
                </Item>
                <Item
                  onClick={() => {
                    this.props.speakingRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    })
                  }}
                >
                  Speaking & Research
                </Item>
                <Item
                  onClick={() => {
                    this.props.bandpageRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    })
                  }}
                >
                  YouTube Music
                </Item>
                <Item
                  onClick={() => {
                    this.props.sideprojectsRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    })
                  }}
                >
                  Side Projects
                </Item>
              </Items>
            </Index>
          </BelowLogo>
        </StyledIntro>
      </IntroContainer>
    )
  }
}

export default Intro
