import React from 'react'
import styled from 'styled-components'
import TwoButtons from '../components/twoButtons'

const EndSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20rem 0 10rem;
  & > h3 {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #333;
    text-align: center;
    max-width: 600px;
    padding: 0 1rem;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 35px;
      padding: 0 2rem;
    }
  }
`

const EndButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 0 0;
  @media (max-width: 768px) {
    display: block;
    margin: 3rem 1rem 0;
    width: 100%;
    padding: 0 1rem;
  }
`

class EndSection extends React.Component {
  render() {
    return (
      <EndSectionContainer>
        <h3>
          Thanks for taking a look. Want to share thoughts, work with me or just
          be internet friends? <br />
          <br />
          I’d love to hear from you.
        </h3>
        <EndButtons>
          <TwoButtons />
        </EndButtons>
      </EndSectionContainer>
    )
  }
}

export default EndSection
