import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import Button from '../components/button'

const CopyTextTransitionStates = {
  showText: {
    opacity: 1,
  },
  hideText: {
    opacity: 0,
  },
  initialPose: 'hideText',
}

const CopiedText = styled(posed.span(CopyTextTransitionStates))`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 45px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0;
  background-color: #4f4f4f;
  border-radius: 50px;
  width: 250px;
  height: 50px;
  @media (max-width: 768px) {
    height: 55px;
    width: 100%;
    border-radius: 0;
    position: fixed;
    bottom: 0;
  }
`

// const PositionCopyText = styled.div`
//   display: inline-block;
//   @media (max-width: 768px) {
//     min-width: 90vw;
//     margin: 10px auto;
//     display: block;
//   }
// `

const ButtonsContainer = styled.div``

class TwoButtons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      copiedState: 'hideText',
    }
  }

  render() {
    const ShowCopiedText = this.state.copied ? (
      <CopiedText pose={this.state.copiedState}>Copied to clipboard</CopiedText>
    ) : null
    return (
      <ButtonsContainer>
        <Button
          onPressed={() => {
            this.setState({
              copied: true,
            })
            setTimeout(() => {
              this.setState({
                copiedState: 'showText',
              })
            }, 100)
            setTimeout(() => {
              this.setState({
                copiedState: 'hideText',
              })
            }, 3000)
          }}
          showArrow={false}
        >
          Copy Email
        </Button>
        <Button
          onPressed={() => {
            window.open(
              'https://twitter.com/messages/compose?recipient_id=&ref_src=twsrc%5Etfw'
            )
          }}
          showArrow={true}
        >
          Twitter DM
        </Button>
        {ShowCopiedText}
      </ButtonsContainer>
    )
  }
}

export default TwoButtons
