import React from 'react'
import styled from 'styled-components'
// import posed from 'react-pose'
import Img from 'gatsby-image'
import H3 from '../components/h3'

const StyledOverlapCarousel = styled.div``

const StyledImg = styled(Img)`
  border-radius: 10px;
`

const HalfImage = styled.div`
  width: 70%;
  position: absolute;
  margin-right: 5rem;
  top: 0px;
  right: 0px;
  z-index: 4;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  /* transition: all 0.5s ease-out; */

  &:nth-child(2) {
    top: 9%;
    right: 5%;
    z-index: 3;
    opacity: 1;
    filter: contrast(20%) brightness(150%);
    transform: scale(0.95);
  }
  &:nth-child(3) {
    top: 18%;
    right: 10%;
    z-index: 2;
    opacity: 1;
    filter: contrast(20%) brightness(150%);
    transform: scale(0.9);
  }
  &:nth-child(4) {
    top: 27%;
    right: 15%;
    z-index: 1;
    opacity: 1;
    filter: contrast(20%) brightness(150%);
    transform: scale(0.85);
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
  &:hover {
    z-index: 6;
    opacity: 1;
    filter: contrast(100%) brightness(100%);
    transform: scale(1);
  }
`

const SideText = styled.div`
  width: 30%;
  padding: 5rem 0rem 0 0rem;
  max-width: 300px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0rem;
  }
`

const CaseP = styled.p`
  color: #666666;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  line-height: 1.45;
`

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: -7rem;
  margin-top: 7rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const ImageStack = styled.div`
  width: 70%;
  height: calc(50vw);
  position: relative;
  margin-right: 2rem;
  @media (max-width: 768px) {
    height: calc(63vw + 120px);
    margin-right: 25%;
    margin-bottom: 2rem;
    width: 110%;
  }
`

class OverlapCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverIndex: 1,
    }
  }

  handleHover(index) {
    this.setState({ hoverIndex: index })
  }

  render() {
    return (
      <StyledOverlapCarousel>
        <TwoColumns>
          <ImageStack>
            <HalfImage>
              <StyledImg fluid={this.props.data.hyper2.childImageSharp.fluid} />
            </HalfImage>
            <HalfImage>
              <StyledImg
                fluid={this.props.data.hyper1_2.childImageSharp.fluid}
              />
            </HalfImage>
            <HalfImage>
              <StyledImg fluid={this.props.data.talk2.childImageSharp.fluid} />
            </HalfImage>
            <HalfImage>
              <StyledImg
                fluid={this.props.data.hyper1_1.childImageSharp.fluid}
              />
            </HalfImage>
          </ImageStack>
          <SideText>
            <H3>Speaking</H3>
            <CaseP>
              I love to teach and to share thoughts from working as
              a designer. Recently my talks focuses on prototyping methods and
              collaboration between designers and engineers.
            </CaseP>
            <CaseP>
              I also run workshops with teams to help grow a deeper
              understanding of design processes, tools and collaboration.
              <br />
              <br />
              A few of my recent talks: <br />
              <br />
              → Hyper Island, Design Lead Class <br />
              → Dribbble Meetup, Bontouch 2019 <br />
              → UXDX Conference, Stockholm 2018 <br />
            </CaseP>
          </SideText>
        </TwoColumns>
      </StyledOverlapCarousel>
    )
  }
}

export default OverlapCarousel
