import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import Page from '../components/page'
import CaseData from '../components/caseData'
import ItemGrid from '../components/itemGrid'
import Intro from '../components/intro'
import EndSection from '../components/endSection'
import Waypoint from 'react-waypoint'
import OverlapCarousel from '../components/overlapCarousel'
import Marking from '../components/marking'
import H3 from '../components/h3'
import Footer from '../components/footer'

const PageTransitionStates = {}

const caseTopTransition = {
  startState: {
    opacity: 0,
    y: 50,
    scale: 0.99,
  },
  enter: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 800,
    },
  },
}

const PageWithTransition = styled(posed.div(PageTransitionStates))`
  opacity: 1;
`

const FixedTop = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 1rem;
  @media (max-height: 850px) {
    opacity: 1 !important;
    margin-bottom: 10rem;
  }
`

const Cases = styled.div`
  margin-top: 15rem;
  padding: 0 1rem;
`

const CaseH2 = styled.h2`
  color: #333;
  font-size: 70px;
  margin-bottom: 3rem;
  z-index: 2;
  position: relative;
  @media (max-width: 1250px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }
`

const Case = styled.div`
  max-width: 1650px;
  margin: 10rem auto;
`

const HalfImage = styled(Img)`
  width: 70%;
  @media (max-width: 768px) {
    width: 170%;
    margin: 1rem 0;
    margin-right: -44%;
  }
`

const HalfImageCards = styled(HalfImage)`
  transform: rotate3d(1, 1, 0, -40deg);
  @media (max-width: 768px) {
    margin-bottom: -6rem;
  }
`

const HalfImageSmaller = styled(Img)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
`

const SideText = styled.div`
  width: 30%;
  padding: 5rem 0rem 0 0rem;
  max-width: 308px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0rem;
  }
`

const CaseP = styled.p`
  color: #666666;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  line-height: 1.45;
`

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const FullImage = styled.div`
  margin-bottom: 10rem;
  padding: 5rem 0rem 0;

  @media (max-width: 768px) {
    padding: 0 0 0;
    margin: 0 -110% 0 -15%;
  }
`

const MediumImage = styled.div`
  max-width: 70%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100vw;
    width: 100vw;
    padding: 0 1rem;
    margin: 2rem calc(-50vw + 50%);
  }
`

const FullBleedImage = styled(FullImage)`
  padding: 0rem 0rem 0;
  margin-top: -11%;
  margin-right: -10%;
  margin-left: -10%;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 0 0;
    margin-top: -90px;
    margin-right: -150%;
    margin-left: -40%;
  }
  @media (min-width: 1920px) {
    margin-top: -8%;
  }
`

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    rgba(246, 247, 248, 0) 0%,
    rgba(246, 247, 248, 1) 100%
  ); /* W3C */
`

const TwoColumnsSmallOffsetCardGrid = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 5rem;
  margin-top: 10rem;
  margin-right: -10%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: -10%;
    margin-left: -10%;
  }
`

const TwoColumnsParagraphs = styled(TwoColumns)`
  width: 70%;
  margin: 0 auto;
  justify-content: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StartDescriptionWrap = styled.div`
  max-width: 1250px;
  margin: 0 auto 25rem;
  opacity: 0;
  padding: 0 1rem;
  @media (min-height: 1400px) {
    padding-bottom: 30rem;
  }
  @media (max-width: 768px) {
    margin: 0 auto 20rem;
  }
`

const StartDescription = styled.h5`
  color: #4f4f4f;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  line-height: normal;
  font-size: 21px;
  width: 50%;
  @media (max-width: 1250px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CaseTop = styled(posed.div(caseTopTransition))`
  padding: 0 0 10px;
  margin: 0 auto 75px;
  position: relative;
  max-width: 1250px;
`

const CaseTopBackground = styled.div`
  position: absolute;
  top: 42px;
  left: -5%;
  right: -5%;
  bottom: 0;
  background: linear-gradient(180deg, #f6f7f8 0%, #ffffff 100%);
  border: 5px solid transparent;
  background-clip: padding-box;
  box-shadow: 0 27px 15px -20px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 25px 25px;
  @media (max-width: 768px) {
    top: 24px;
    border-radius: 0px;
  }
`

const Emphasis = styled.span`
  color: #333;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: normal;
  }
`

const ShadowedImage = styled(MediumImage)`
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.4));
`

const ShadowedImageWeak = styled(MediumImage)`
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.2));
`

const IntroWithRef = React.forwardRef((props, ref) => {
  return <Intro innerRef={ref} {...props} />
})

let lastScrollY = 0
let ticking = false

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoStatus: 'startState',
      scrollPosition: ['startState'],
      firstLine: 'startState',
      secondLine: 'startState',
      thirdLine: 'startState',
      fourthLine: 'startState',
      fifthLine: 'startState',
      sixthLine: 'startState',
      seventhLine: 'startState',
      eigthLine: 'startState',
      firstBox: 'startState',
      secondBox: 'startState',
      thirdBox: 'startState',
      fourthBox: 'startState',
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    lastScrollY = window.scrollY
    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.nav.current.style.opacity = `${1 - (lastScrollY / 100) * 0.3}`
        if (lastScrollY < 300) {
          this.subNav.current.style.opacity = 0
        } else {
          this.subNav.current.style.opacity = `${0 +
            ((lastScrollY - 300) / 100) * 0.5}`
        }
        ticking = false
      })

      ticking = true
    }
  }

  getTransitionStates(status) {
    if (['entering', 'entered'].includes(status)) {
      return ['visible', 'logoChain']
    } else if (['exiting'].includes(status)) {
      return 'exitedState'
    } else if (['exited'].includes(status)) {
      return 'startState'
    } else if (['POP'].includes(status)) {
      return 'visible'
    }
  }

  nav = React.createRef()
  subNav = React.createRef()
  minecraft = React.createRef()
  speaking = React.createRef()
  bandpage = React.createRef()
  sideprojects = React.createRef()

  handleWaypointEnter(position) {
    if (position === 'minecraft') {
      this.setState({
        firstLine: 'line',
      })
    } else if (position === 'minecraft2') {
      this.setState({
        secondLine: 'line',
      })
    } else if (position === 'speaking') {
      this.setState({
        thirdLine: 'line',
        ninthLine: 'lineDelayed',
      })
    } else if (position === 'bandpage') {
      this.setState({
        fourthLine: 'line',
        fifthLine: 'lineDelayed',
      })
    } else if (position === 'sideprojects') {
      this.setState({
        sixthLine: 'line',
        seventhLine: 'lineDelayed',
        eigthLine: 'lineDelayedMore',
      })
    }
  }

  swoosh(position) {
    if (position === 'minecraftSwoosh') {
      this.setState({
        firstBox: 'enter',
      })
    }
    if (position === 'speakingSwoosh') {
      this.setState({
        secondBox: 'enter',
      })
    }
    if (position === 'bandpageSwoosh') {
      this.setState({
        thirdBox: 'enter',
      })
    }
    if (position === 'sideprojectsSwoosh') {
      this.setState({
        fourthBox: 'enter',
      })
    }
  }

  render() {
    return (
      <Page>
        <TransitionState>
          {({ transitionStatus: status }) => {
            return (
              <PageWithTransition
                pose={this.getTransitionStates(status)}
                initialPose={'startState'}
              >
                <Waypoint
                  // onLeave={}
                  scrollableAncestor={'window'}
                  onEnter={() => this.handleWaypointEnter('startState')}
                />
                <FixedTop ref={this.nav}>
                  <IntroWithRef
                    status={status}
                    profilePhoto={this.props.data.profilePhoto}
                    minecraftRef={this.minecraft}
                    speakingRef={this.speaking}
                    bandpageRef={this.bandpage}
                    sideprojectsRef={this.sideprojects}
                  />
                </FixedTop>
                <Cases>
                  <StartDescriptionWrap ref={this.subNav}>
                    <StartDescription>
                      I love creating functional, sustainable and deeply human
                      technology products for a post-digital era. <br />
                      <br /> In my design approach I tend to include research,
                      interactive prototypes and close engineering collaboration
                      for pragmatic outcomes that stresses the details. <br />
                      <br />I thrive when following a technology and business
                      case all the way through to a delightful user experience.
                      <br />
                      <br />
                      On this page I've collected a selection of my work.
                    </StartDescription>
                  </StartDescriptionWrap>
                  <CaseTop pose={this.state.firstBox} ref={this.minecraft}>
                    <CaseH2>
                      Since 2017 I've been designing UX for {' '}
                      <Emphasis>
                        Minecraft{' '}
                        <Marking markingStatus={this.state.firstLine} />
                      </Emphasis> while growing the team.
                    </CaseH2>
                    <CaseData
                      description="How do you grow a design organization for the world's biggest game?"
                      descriptionTwo="With more than 100M players around the world on all imaginable platforms, we grew a design and UI engineering team and that honored the expressive qualities of the historic game."
                      year="2017 – 2020"
                      roles="Product Design, Design Systems and Design Organization"
                    />
                    <Waypoint
                      bottomOffset="-200px"
                      scrollableAncestor={'window'}
                      onEnter={() => this.swoosh('minecraftSwoosh')}
                    />
                    <Waypoint
                      bottomOffset="-200px"
                      scrollableAncestor={'window'}
                      onEnter={() => this.handleWaypointEnter('minecraft')}
                    />
                    <CaseTopBackground />
                  </CaseTop>
                  <FullBleedImage>
                    <GradientOverlay />
                    <Img
                      fluid={
                        this.props.data.minecraftDevices.childImageSharp.fluid
                      }
                    />
                  </FullBleedImage>
                  <Case style={{ margin: '10rem auto 0rem' }}>
                    <CaseTop pose={this.state.secondBox} ref={this.speaking}>
                      <CaseH2>
                        I’m speaking & leading workshops in{' '}
                        <Emphasis>
                          prototyping
                          <Marking
                            markingStatus={this.state.thirdLine}
                            alternative={true}
                          />
                        </Emphasis>{' '}
                        and{' '}
                        <Emphasis>
                          collaboration{' '}
                          <Marking
                            markingStatus={this.state.ninthLine}
                            alternative={false}
                          />
                        </Emphasis>{' '}
                        methods, based on research work from my M.Sc thesis
                      </CaseH2>
                      <CaseData
                        description="In design school I completed a M.Sc thesis on prototyping methods for interaction designers. Since then I have applied my framework in teaching design students and professionals. "
                        year="2017 –"
                        roles="Research, Speaking, Design Workshops"
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.swoosh('speakingSwoosh')}
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.handleWaypointEnter('speaking')}
                      />
                      <CaseTopBackground />
                    </CaseTop>
                    <OverlapCarousel data={this.props.data} />
                    <TwoColumnsSmallOffsetCardGrid>
                      <HalfImageCards
                        fluid={this.props.data.talk4.childImageSharp.fluid}
                      />
                      <SideText>
                        <H3>25 Principles</H3>
                        <CaseP>
                          The outcome of my thesis research was a card deck with 25
                          techniques for improving prototyping and the
                          surrounding design process.
                        </CaseP>
                        <CaseP>
                          The deck was developed through workshops
                          with design teams in Stockholm, together with extensive prototyping work
                          for Minecraft where I designed interactions for the game in
                          tools such as Framer and Origami Studio.
                        </CaseP>
                      </SideText>
                    </TwoColumnsSmallOffsetCardGrid>
                  </Case>
                  <Case  style={{ marginTop: '5rem' }}>
                    <CaseTop pose={this.state.thirdBox} ref={this.bandpage}>
                      <CaseH2>
                        Previously, I did engineering at{' '}
                        <Emphasis>
                          YouTube Music
                          <Marking markingStatus={this.state.fourthLine} />
                        </Emphasis>{' '}
                        and UI/UX at{' '}
                        <Emphasis>
                          BandPage
                          <Marking
                            markingStatus={this.state.fifthLine}
                            alternative={true}
                          />
                        </Emphasis>{' '}
                        (acquired by Google)
                      </CaseH2>
                      <CaseData
                        description="BandPage was a music software startup that enabled 500K+ artists to manage their data on the major music streaming services. The product required complex UIs for data management, and a precise marketing site."
                        year="2015 – 2016"
                        roles="UI design, React UI Engineering"
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.swoosh('bandpageSwoosh')}
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.handleWaypointEnter('bandpage')}
                      />
                      <CaseTopBackground />
                    </CaseTop>
                    <ShadowedImage>
                      <Img
                        fluid={
                          this.props.data.bandpageSite.childImageSharp.fluid
                        }
                      />
                    </ShadowedImage>
                    <TwoColumnsParagraphs>
                      <SideText style={{ padding: '3rem 0rem 7rem 0rem' }}>
                        <H3>Marketing site</H3>
                        <CaseP>
                          Working with our design director, I redesigned the
                          BandPage marketing site with new visual design, updated
                          value propositions and a new onboarding flow.
                        </CaseP>
                        <CaseP>
                          This helped us as the startup's audience
                          shifted within the music management industry. What
                          once was a fan-serving product for indie musicians
                          grew to serve labels and managers as the
                          apps grew more popular for established artists.
                        </CaseP>
                      </SideText>
                    </TwoColumnsParagraphs>
                    <TwoColumns style={{ justifyContent: 'center' }}>
                      <HalfImageSmaller
                        fluid={
                          this.props.data.bpNotification.childImageSharp.fluid
                        }
                      />
                      <SideText>
                        <H3>Designing and building data tools</H3>
                        <CaseP>
                          One of our big challenges was to enable artists to have
                          control over what data was distributed across the BandPage
                          network.
                        </CaseP>
                        <CaseP>
                          Fast, clear and communicative interfaces for
                          data tools was crucial to enhance our artist
                          profile data before it got distributed into our partner network, and used in very different
                          contexts.
                        </CaseP>
                      </SideText>
                    </TwoColumns>
                    <TwoColumnsParagraphs>
                      <SideText style={{ padding: '5rem 0rem 2rem 0rem' }}>
                        <H3>BandPage Editor</H3>
                        <CaseP>
                          I also contributed with design and UI engineering for the BandPage web editor to improve the
                          usability and visual presentation.
                        </CaseP>
                        <CaseP>
                        This was our main UI used by either artists
                          themselves, managers or record labels.
                        </CaseP>
                      </SideText>
                    </TwoColumnsParagraphs>
                    <ShadowedImageWeak>
                      <Img
                        fluid={this.props.data.bpLaptop.childImageSharp.fluid}
                      />
                    </ShadowedImageWeak>
                  </Case>
                  <Case>
                    <CaseTop
                      pose={this.state.fourthBox}
                      ref={this.sideprojects}
                    >
                      <CaseH2>
                        Other projects include{' '}
                        <Emphasis>
                          woodworking
                          <Marking markingStatus={this.state.sixthLine} />
                        </Emphasis>
                        ,{' '}
                        <Emphasis>
                          VR/AR prototyping
                          <Marking
                            markingStatus={this.state.seventhLine}
                            alternative={true}
                          />
                        </Emphasis>{' '}
                        and{' '}
                        <Emphasis>
                          Interactive experiments
                          <Marking markingStatus={this.state.eigthLine} />
                        </Emphasis>
                      </CaseH2>
                      <CaseData
                        description="I love to experiment with new tools and technology. In some cases it takes the form of a physical product, such as the Pixel lamp, a recent woodworking and electronics project. "
                        year="2012 – "
                        roles="Design, research, engineering"
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.handleWaypointEnter('sideprojects')}
                      />
                      <Waypoint
                        bottomOffset="-200px"
                        scrollableAncestor={'window'}
                        onEnter={() => this.swoosh('sideprojectsSwoosh')}
                      />
                      <CaseTopBackground />
                    </CaseTop>
                  </Case>
                </Cases>
                <ItemGrid
                  imageOne={this.props.data.pixelLamp_1.childImageSharp.fluid}
                  imageTwo={this.props.data.origami.childImageSharp.fluid}
                  imageThree={this.props.data.signals.childImageSharp.fluid}
                  imageFour={this.props.data.beam.childImageSharp.fluid}
                  imageFive={this.props.data.rb.childImageSharp.fluid}
                  imageSix={this.props.data.threed.childImageSharp.fluid}
                  imageSeven={this.props.data.collections.childImageSharp.fluid}
                  imageEight={this.props.data.devices.childImageSharp.fluid}
                />
                <EndSection />
                <Footer />
              </PageWithTransition>
            )
          }}
        </TransitionState>
      </Page>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    profilePhoto: file(relativePath: { eq: "profile-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 50) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    minecraftswitch: file(relativePath: { eq: "minecraftswitch.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    minecraftDevices: file(relativePath: { eq: "Devices_nocrop.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    minecraftWireframes: file(relativePath: { eq: "wireframes.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bpPhonesThree: file(relativePath: { eq: "bp-phones.png" }) {
      childImageSharp {
        fluid(maxWidth: 925) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bandpageSite: file(relativePath: { eq: "bandpage-site.png" }) {
      childImageSharp {
        fluid(maxWidth: 925) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bpLaptop: file(relativePath: { eq: "bandpage-editor.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bpPhonesTwo: file(relativePath: { eq: "bp-new-phones.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bpNotification: file(relativePath: { eq: "bp_notification.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bpApp: file(relativePath: { eq: "bp-editor-merch-update.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    hyper2: file(
      relativePath: { eq: "Hyper_Design_Lead_Prototyping.002.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    talk2: file(relativePath: { eq: "Mindful.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    talk3: file(relativePath: { eq: "final_presentation_002.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    talk4: file(relativePath: { eq: "card-grid-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    hyper1_1: file(relativePath: { eq: "prototyping_hyper_IAD18.011.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    hyper1_2: file(relativePath: { eq: "prototyping_hyper_IAD18.002.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    hyper1_3: file(relativePath: { eq: "prototyping_hyper_IAD18.001.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    pixelLamp_1: file(relativePath: { eq: "pixel-5-tall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    pixelLamp_2: file(relativePath: { eq: "pixel-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    origami: file(relativePath: { eq: "origami.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    signals: file(relativePath: { eq: "signals.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    beam: file(relativePath: { eq: "beam.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    devices: file(relativePath: { eq: "dev.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    rb: file(relativePath: { eq: "rb.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    collections: file(relativePath: { eq: "collections.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    threed: file(relativePath: { eq: "td.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    minecraftLegacyFrame: file(relativePath: { eq: "mcFrame.png" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
