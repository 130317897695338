import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import experimentsVideo from '../images/experiments-cropped.mp4'
import signalsVideo from '../images/s1-c-540.mp4'
import beamVideo from '../images/beammm.mp4'

const VideoContainer = styled.div`
  height: 138%;
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`

const StyledVideo = styled.video`
  width: 100%;
  opacity: 0.5;
  transition: all 0.5s ease-out;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  &:hover {
    opacity: 1;
  }
`

const ItemGrid = styled.div``

const ItemGridVideo = styled.div`
  width: 1250px;
  margin: 0 auto 10rem;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  height: auto;
  @media (max-width: 1250px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: auto;
    grid-row-gap: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const ItemGridStatic = styled.div`
  width: 100%;
  margin: 0 auto 10rem;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 30px;
  height: auto;
  margin-top: -5rem;
  @media (min-width: 768px) {
    display: none;
  }
`

const AspectRatioBoxInner = styled.div`
  position: relative;
`

const GridImage = styled(Img)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  transition: all 0.35s ease-out;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  div,
  picture,
  div > div {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }


  @media (max-width: 768px) {
    opacity: 1;
  }
`

const AspectRatioBox = styled.div`
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-bottom: 57.3%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 5px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 27px 15px -20px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  border: 5px solid transparent;
  background-clip: padding-box;
  transition: all 0.35s ease-out;
  &:hover ${GridImage} {
    opacity: 1;
  }
  &:hover {
    box-shadow: 0 32px 20px -20px rgba(0, 0, 0, 0.1);
    transform: scale(1.01) translateY(-3px);
  }
`

const ItemDescription = styled.div`
  height: 50%;
  padding: 1rem 2rem 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 1rem 1.5rem 1rem;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 400;
    color: #888888;
    line-height: 1.3;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  a,
  a:visited {
    display: block;
    text-align: left;
    color: #888888;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
      Helvetica, Arial, sans-serif;
    text-decoration: none;
    :hover {
      cursor: pointer;
      color: #aaaaaa;
    }
  }
`

class Logo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoStatus: 'startState',
    }
  }

  mouseEnterVideo(e) {
    e.currentTarget.play()
  }

  mouseLeaveVideo(e) {
    e.currentTarget.pause()
  }

  render() {
    return (
      <ItemGrid>
        <ItemGridVideo>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageEight} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Figma Community: Device Outlines</h2>
                <h3>
                  Open source vector outlines of devices and input hardware for consoles and gaming platforms.
                </h3>
              </div>
              <a href="https://www.figma.com/community/file/817697371474907588">
                Download →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageFive} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Generative Design</h2>
                <h3>
                  I'm currently building Figma plugins for generative art and design
                </h3>
              </div>
              <a href="https://twitter.com/oscrse/status/1262416603880325130">
                Updates →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageSeven} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Pinterest Collections</h2>
                <h3>
                  Every month I'm posting my latest moodboard on Twitter.
                </h3>
              </div>
              <a href="https://www.pinterest.se/oscrse/pins/">
                Follow along on Pinterest →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageSix} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>3D experiments</h2>
                <h3>
                  I've recently picked up 3D modelling for a recent cabin renovation project.
                </h3>
              </div>
              <a href="https://twitter.com/oscrse/status/1260550607489941504">
                Updates →
              </a>
            </ItemDescription>
          </Item>
          <Item>
            <VideoContainer>
              <StyledVideo
                src={experimentsVideo}
                muted
                loop
                poster="images/experiments.jpg"
                //autoPlay
                preload="none"
                onMouseEnter={this.mouseEnterVideo}
                onMouseLeave={this.mouseLeaveVideo}
              />
            </VideoContainer>
            <ItemDescription>
              <div>
                <h2>Origami: Interaction starters</h2>
                <h3>
                  Starter kits for trying out prototyping with high-fidelity
                  interactivity in Origami Studio.
                </h3>
              </div>
              <a href="https://drive.google.com/drive/folders/1cqcO3z83yeQEnJPQZ-eRRjOmcJG9PDdo?usp=sharing">
                Download →
              </a>
            </ItemDescription>
          </Item>
          <Item>
            <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageOne} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Pixel Light</h2>
                <h3>
                  Building a minimal, LED-lit light surface for mood lighting.
                </h3>
              </div>
              <a href="/notes/pixel-light">Read more →</a>
            </ItemDescription>
          </Item>
          <Item>
            <VideoContainer>
              <StyledVideo
                src={signalsVideo}
                muted
                loop
                poster="images/signals.jpg"
                //autoPlay
                preload="none"
                onMouseEnter={this.mouseEnterVideo}
                onMouseLeave={this.mouseLeaveVideo}
              />
            </VideoContainer>
            <ItemDescription>
              <div>
                <h2>Signals</h2>
                <h3>Research project that prototyped street indicators for autonomous vehicles.</h3>
              </div>
            </ItemDescription>
          </Item>
          <Item>
            <VideoContainer>
              <StyledVideo
                src={beamVideo}
                muted
                loop
                poster="images/beam.jpg"
                //autoPlay
                preload="none"
                onMouseEnter={this.mouseEnterVideo}
                onMouseLeave={this.mouseLeaveVideo}
              />
            </VideoContainer>
            <ItemDescription>
              <div>
                <h2>Dashboard Avatars</h2>
                <h3>
                  Exploring social interaction for drivers using VR prototypes
                  and driving simulators.
                </h3>
              </div>
            </ItemDescription>
          </Item>
        </ItemGridVideo>
        <ItemGridStatic>
        <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageEight} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Figma Community: Device Outlines</h2>
                <h3>
                  Open source vector outlines of devices and input hardware for consoles and gaming platforms.
                </h3>
              </div>
              <a href="https://www.figma.com/community/file/817697371474907588">
                Download →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageFive} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Generative Design</h2>
                <h3>
                  I'm currently exploring making Figma plugins for generative art and design
                </h3>
              </div>
              <a href="https://twitter.com/oscrse/status/1262416603880325130">
                Updates →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageSeven} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Pinterest Collections</h2>
                <h3>
                  Every month I'm posting my latest moodboard on Twitter.
                </h3>
              </div>
              <a href="https://www.pinterest.se/oscrse/pins/">
                Follow along on Pinterest →
              </a>
            </ItemDescription>
          </Item>
          <Item>
          <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageSix} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>3D experiments</h2>
                <h3>
                  I've recently picked up 3D modelling for a recent cabin renovation project.
                </h3>
              </div>
              <a href="https://twitter.com/oscrse/status/1260550607489941504">
                Updates →
              </a>
            </ItemDescription>
          </Item>
          <Item>
            <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageOne} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Pixel Light</h2>
                <h3>
                  Building a minimal, LED-lit light surface for mood lighting.
                </h3>
              </div>
              <a href="/notes/pixel-light">Read more →</a>
            </ItemDescription>
          </Item>
          <Item>
            <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageTwo} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Origami: Interaction starters</h2>
                <h3>
                  Starter kits for trying out prototyping with high-fidelity
                  interactivity in Origami Studio.
                </h3>
              </div>
              <a href="https://drive.google.com/drive/folders/1cqcO3z83yeQEnJPQZ-eRRjOmcJG9PDdo?usp=sharing">
                Download →
              </a>
            </ItemDescription>
          </Item>
          <Item>
            <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageThree} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Signals</h2>
                <h3>Prototyping street indicators for autonomous vehicles.</h3>
              </div>
            </ItemDescription>
          </Item>
          <Item>
            <AspectRatioBox>
              <AspectRatioBoxInner>
                <GridImage fluid={this.props.imageFour} />
              </AspectRatioBoxInner>
            </AspectRatioBox>
            <ItemDescription>
              <div>
                <h2>Dashboard Avatars</h2>
                <h3>
                  Exploring social interaction for drivers using VR prototypes
                  and driving simulators.
                </h3>
              </div>
            </ItemDescription>
          </Item>
        </ItemGridStatic>
      </ItemGrid>
    )
  }
}

export default Logo
