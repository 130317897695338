import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

const markingTransitionStates = {
  startState: {
    opacity: 0.5,
    width: '0%',
  },
  line: {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 600,
      ease: [0.89, 0.01, 0.83, 0.67],
    },
  },
  lineDelayed: {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 600,
      delay: 500,
      ease: [0.89, 0.01, 0.83, 0.67],
    },
  },
  lineDelayedMore: {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 600,
      delay: 1500,
      ease: [0.89, 0.01, 0.83, 0.67],
    },
  },
}

const PosedMarking = styled(posed.div(markingTransitionStates))`
  position: absolute;
  height: 100px;
  top: 0px;
  left: -3px;
  right: 3px;
  bottom: 0px;
  z-index: -1;
  transform: rotate(-1deg);
  @media (max-width: 1250px) {
    height: 70px;
  }
  @media (max-width: 768px) {
    height: 45px;
    height: 100%;
  }
`

class Logo extends React.Component {
  render() {
    const svgType = this.props.alternative ? (
      <svg
        viewBox="0 0 473 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
      >
        <path
          d="M0.500161 13.001C0.500821 9.79018 3.63194 7.51284 6.68687 8.5013L14.2563 10.9505C16.407 11.6463 18.6486 12.0214 20.9087 12.0634L190.622 15.2224L412.355 12.1674L465.213 6.82146C466.606 6.68055 468 7.08574 469.1 7.95171V7.95171C470.856 9.33316 471.815 11.4929 471.663 13.7216L470.127 36.2656L471.485 56.0824C471.609 57.8853 471.119 59.677 470.097 61.167V61.167C468.586 63.3691 466.077 64.674 463.406 64.6472L239.734 62.4062L12.0772 68.3857C6.56643 68.5304 1.9779 64.1881 1.81882 58.6777L0.500161 13.001V13.001Z"
          fill="url(#paint1_linear)"
        />
        <defs>
          <linearGradient
            id="paint1_linear"
            x1="1"
            y1="39"
            x2="473"
            y2="39.0001"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#42EA88" stopOpacity="0.45" />
            <stop offset="1" stopColor="#42EA88" stopOpacity="0.35" />
          </linearGradient>
        </defs>
      </svg>
    ) : (
      <svg
        viewBox="0 0 389 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
      >
        <path
          d="M0.52783 12.8572C0.469184 9.44165 3.26986 6.66643 6.68469 6.75623L156.397 10.6935L338.897 8.42042L381.479 2.82968C383.164 2.6084 384.853 3.21427 386.013 4.45659V4.45659C387.222 5.75144 387.859 7.47912 387.778 9.24905L386.574 35.6832L387.848 58.8273C387.925 60.2378 387.633 61.6442 386.999 62.9068V62.9068C385.598 65.6983 382.718 67.4362 379.596 67.3741L197.066 63.7429L8.58366 69.2908C4.6858 69.4055 1.44569 66.313 1.37874 62.414L0.52783 12.8572Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="389"
            y1="37"
            x2="-6e-06"
            y2="37"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F2D64C" stopOpacity="0.32" />
            <stop offset="1" stopColor="#F2D64C" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg>
    )
    return (
      <PosedMarking pose={this.props.markingStatus}>{svgType}</PosedMarking>
    )
  }
}

export default Logo
