import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

const logoTransitionStates = {
  // startState: {
  //   strokeOpacity: 0,
  //   pathLength: 1,
  // },
  // visible: {
  //   strokeOpacity: 1,
  //   pathLength: 100,
  //   transition: {
  //     duration: 5000,
  //     // ease: [0.09, 1.13, 0.14, 0.81],
  //   },
  // },
}

const logoWrapperStates = {
  // startState: {
  //   opacity: 0,
  // },
  // visible: {
  //   opacity: 1,
  //   transition: {
  //     duration: 500,
  //   },
  // },
  // // logoChain: {
  // //   opacity: 0,
  // //   delay: 2250,
  // //   transition: {
  // //     duration: 500,
  // //   },
  // // },
  // exitedState: {
  //   opacity: 0,
  //   applyAtStart: { display: 'none' },
  // },
}

const logoContainerTransitionStates = {
  startState: {
    opacity: 1,
  },
  exitedState: {
    opacity: 1,
    delay: 300,
    transition: {
      duration: 300,
    },
    applyAtStart: { display: 'flex' },
  },
  logoChain: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    delay: 300,
    transition: {
      duration: 500,
    },
  },
}

const PosedLogo = styled(posed.path(logoTransitionStates))``

const LogoContainer = styled(posed.div(logoContainerTransitionStates))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  z-index: 100;
`

const StyledLogo = styled(posed.div(logoWrapperStates))`
  stroke-width: 1;
  opacity: 0;
  stroke: #777777;
  width: 13rem;
  stroke-width: 1;
  margin: 0 0 0 0;
  fill-opacity: 0;
  @media (max-width: 1250px) {
    width: 10rem;
  }
  @media (max-width: 768px) {
    width: 10rem;
  }
`

class Logo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoStatus: 'startState',
    }
  }

  render() {
    return (
      <LogoContainer>
        <StyledLogo initialPose={['startState']}>
          <svg viewBox="-2 -2 148 147">
            <PosedLogo
              d="M71.9104 143C111.625 143 143.821 110.988 143.821 71.5C143.821 32.0116 111.625 0 71.9104 0C32.1954 0 0 32.0116 0 71.5C0 110.988 32.1954 143 71.9104 143ZM105.468 112.358H85.461L38.3516 30.6433H58.3585L105.468 112.358ZM105.235 30.6433V58.6596C93.6034 56.325 86.3916 39.5152 85.461 30.6433H105.235ZM58.3585 112.358H38.5842V84.3413C50.2161 86.676 57.428 103.486 58.3585 112.358Z"
              pose={['visible', 'logoChain']}
              initialPose={['startState']}
            />
          </svg>
        </StyledLogo>
      </LogoContainer>
    )
  }
}

export default Logo
